import { ConfigOverrideRule, DataFrame, Field, Labels, PanelData } from '@grafana/data';
import { DataQuery } from '@grafana/schema';

import { isString } from 'lodash';

import { MAX_SAMPLES_PER_TRAINING, PLUGIN_ROOT } from 'consts';
import {
  DatadogSLO,
  ForecastLabelValue,
  Job,
  JobMetricQueries,
  JobSeriesInfo,
  JobSeriesNames,
  LabelValues,
  NameToLabels,
  NameToType,
  TimeUnit,
} from 'types';

import { timeUnitToSeconds } from './timeConversion';

/**
 * Get the query string specified for a job.
 */
export const getJobQueryString = (job: Job): string | undefined => getQueryString(job.datasourceType, job.queryParams);

interface HasQueryString {
  target?: string;
  expr?: string;
  metric?: string;
  slo?: DatadogSLO;
  query?: string;
  rawSql?: string;
}

/**
 * Get the populated query string for a given datasource type.
 */
export const getQueryString = (
  datasourceType?: string,
  queryParams?: DataQuery & HasQueryString
): string | undefined => {
  if (queryParams == null) {
    return undefined;
  }
  switch (datasourceType) {
    case 'grafana-graphite-datasource':
    case 'graphite':
      if (isString(queryParams.target)) {
        return queryParams.target;
      }
      break;
    case 'grafana-prometheus-datasource':
    case 'grafana-amazonprometheus-datasource':
    case 'prometheus':
      if (isString(queryParams.expr)) {
        return queryParams.expr;
      }
      break;
    case 'grafana-loki-datasource':
    case 'loki':
      if (isString(queryParams.expr)) {
        return queryParams.expr;
      }
      break;
    case 'grafana-datadog-datasource':
      if (isString(queryParams.metric)) {
        return queryParams.metric;
      }
      if (queryParams.slo !== undefined) {
        return queryParams.slo.name;
      }
      break;
    case 'grafana-influxdb-datasource':
    case 'influxdb':
      if (isString(queryParams.query)) {
        return queryParams.query;
      }
      break;
    case 'postgres':
    case 'grafana-postgresql-datasource':
    case 'doitintl-bigquery-datasource':
    case 'grafana-bigquery-datasource':
    case 'grafana-snowflake-datasource':
      if (isString(queryParams.rawSql)) {
        return queryParams.rawSql;
      }
      break;
    default:
  }
  return undefined;
};

/**
 * Get the unfiltered actual and predicted queries for a job.
 *
 * Additional selectors can be added by including them in the `extraLabels`
 * argument.
 *
 * @param {Job} job
 * @param {Labels} extraLabels Any additional selectors to be added to the
 *                             query.
 * @return JobMetricQueries The actual and predicted metric queries.
 */
export const getJobMetricQueries = (job: Job, extraLabels?: Labels): JobMetricQueries => {
  // eslint-disable-next-line camelcase
  const selector = getSeriesSelector(extraLabels ?? {});
  return {
    actual: `${job.metric}:actual${selector}`,
    predicted: `${job.metric}:predicted${selector}`,
  };
};

/**
 * Get a string suitable for use as a Prometheus selector for a set of labels.
 *
 * If the `labels` object is empty, this returns the empty string. Otherwise
 * it returns a comma separated list of label matchers in curly braces.
 *
 * @example
 * // returns ''
 * getSeriesSelector({})
 * @example
 * // returns '{job="prometheus", instance="demo.robustperception.io:9090"}'
 * getSeriesSelector({ job: 'prometheus', instance: 'demo.robustperception.io:9090' })
 */
export const getSeriesSelector = (labels: Labels): string => {
  const keys = Object.keys(labels);
  if (keys.length === 0) {
    return '';
  } else {
    const promLabels = keys
      .slice(1)
      .reduce(
        (acc: string, curr: string) => `${acc}, ${curr}="${labels[curr]!}"`,
        `${keys[0]!}="${labels[keys[0]!] ?? ''}"`
      );
    return `{${promLabels}}`;
  }
};

/**
 * Get the queries for the 'predicted' and 'actual' series of a job.
 */
export const getSelectedJobQueries = (job: Job, serieses: JobSeriesInfo[]): DataQuery[] => {
  const queries: DataQuery[] = [];
  let i = 1;
  for (const series of serieses) {
    const jobMetricQueries = getJobMetricQueries(job, series.labels);
    queries.push(
      { refId: `P${i}`, expr: jobMetricQueries.predicted, queryType: 'metric' } as DataQuery,
      { refId: `A${i}`, expr: jobMetricQueries.actual, queryType: 'metric' } as DataQuery
    );
    i += 1;
  }
  return queries;
};

export const getMinimumUniqueUnderlyingSeriesLabels = (data: PanelData | undefined): Labels[] => {
  // Mapping from label name to observed label values.
  const labelValues: LabelValues = {};
  // List of label combinations.
  const labelCombinations: Labels[] = [];

  const excludedLabels = ['ml_forecast', '__name__'];

  for (const series of data?.series ?? []) {
    // There should always be one 'time' field and one non-'time' field in the returned
    // series, since we control their format.
    const valueField = series.fields.find((f: Field) => f.type !== 'time');
    if (valueField != null) {
      // Add all the current labels to the list of observed values.
      for (const label in valueField.labels) {
        if (!excludedLabels.includes(label)) {
          const labelVal = valueField.labels[label]!;
          if (Object.keys(labelValues).includes(label)) {
            if (!labelValues[label]!.includes(labelVal)) {
              labelValues[label]!.push(labelVal);
            }
          } else {
            labelValues[label] = [labelVal];
          }
        }
      }

      const valueFieldLabels = valueField.labels ?? {};

      // Filter out the excluded labels before adding them to the label combinations.
      labelCombinations.push(
        Object.fromEntries(Object.entries(valueFieldLabels).filter(([k, _]) => !excludedLabels.includes(k)))
      );
    }
  }

  // Determine the unique label mappings across all returned series.
  const uniqueLabels = labelCombinations.reduce((acc: Labels[], current: Labels) => {
    const x = acc.find((item) => JSON.stringify(item) === JSON.stringify(current));
    return Boolean(x) ? acc : acc.concat([current]);
  }, []);

  // Remove any label/value pairs which only have one unique value across all series.
  // Each of these will represent one 'JobSeries', and will have four series associated with it.
  const minimumUniqueLabels = uniqueLabels.map((l) =>
    Object.fromEntries(Object.entries(l).filter(([k, _]) => labelValues[k]!.length > 1))
  );
  return minimumUniqueLabels;
};

/**
 * Get a list of JobSeriesInfo given the data returned by the MLAPI.
 *
 * This handles organising the multiple series returned from the MLAPI into
 * data structures which can be used to format plots nicely. Generally this
 * means digging into the returned 'predicted' and 'actual' series', linking
 * together those which refer to the same underlying source series,
 * and determining which labels actually differ between those underlying source
 * series.
 */
export const getJobSeriesInfo = (data: PanelData): JobSeriesInfo[] => {
  // Mapping from series name to column type.
  const seriesTypes: NameToType = {};
  // Mapping from series name to labels.
  const seriesLabels: NameToLabels = {};

  // Populate the above objects/arrays from the data.
  data.series.forEach((series: DataFrame) => {
    // There should always be one 'time' field and one non-'time' field in the returned
    // series, since we control their format.
    const valueField = series.fields.find((f: Field) => f.type !== 'time') as Field | undefined;
    // Prior to the dataplane adapter, this was on the series name.
    // Since then, it's on the first non-time field.
    // See https://github.com/grafana/grafana/pull/65237 for details.
    const seriesName = series.name ?? valueField?.config?.displayNameFromDS;
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!seriesName || !valueField || !valueField.labels) {
      // This error was causing problems and crashing the whole page
      // Might be worth uncommenting when and if we have better error catching
      // We may also want to put logging here.
      //throw new Error(data.errors?.toString() ?? "Query returned invalid data");
      return;
    }

    const seriesType = valueField.labels['ml_forecast'] as ForecastLabelValue;
    seriesTypes[seriesName] = seriesType;
    seriesLabels[seriesName] = valueField.labels;
  });

  // List of label combinations.
  const minimumUniqueLabels: Labels[] = getMinimumUniqueUnderlyingSeriesLabels(data);

  const jobSeriesInfos = [];
  for (const labelCombination of minimumUniqueLabels) {
    // Find the names of the 4 series which have this combination of labels.
    const validSeriesNames = Object.entries(seriesLabels)
      .filter(([_, labels]) => Object.keys(labelCombination).every((key) => labels[key] === labelCombination[key]))
      .map(([name, _]) => name);

    const actual = validSeriesNames.find((n) => seriesTypes[n] === 'y') as string;
    const predicted = validSeriesNames.find((n) => seriesTypes[n] === 'yhat') as string;
    const lower = validSeriesNames.find((n) => seriesTypes[n] === 'yhat_lower') as string;
    const upper = validSeriesNames.find((n) => seriesTypes[n] === 'yhat_upper') as string;
    jobSeriesInfos.push({
      names: {
        actual,
        predicted,
        lower,
        upper,
      },
      labels: labelCombination,
    });
  }
  return jobSeriesInfos;
};

const createNewNames = ({ labels }: JobSeriesInfo): JobSeriesNames => {
  const suffix = Object.keys(labels).length > 0 ? ` - ${getSeriesSelector(labels)}` : '';
  return {
    actual: `Actual${suffix}`,
    predicted: `Predicted${suffix}`,
    upper: `Predicted (upper)${suffix}`,
    lower: `Predicted (lower)${suffix}`,
  };
};

/**
 * Create the fieldOverrides for a JobSeries.
 */
export const createFieldOverrides = (info: JobSeriesInfo): ConfigOverrideRule[] => {
  const { names } = info;
  const newNames = createNewNames(info);
  return [
    {
      matcher: { id: 'byName', options: names.predicted },
      properties: [
        { id: 'displayName', value: newNames.predicted },
        { id: 'color', value: { fixedColor: 'blue', mode: 'fixed' } },
      ],
    },
    {
      matcher: { id: 'byName', options: names.lower },
      properties: [
        { id: 'displayName', value: newNames.lower },
        { id: 'color', value: { fixedColor: 'blue', mode: 'fixed' } },
        { id: 'custom.lineWidth', value: 0 },
        { id: 'custom.hideFrom', value: { legend: true } },
      ],
    },
    {
      matcher: { id: 'byName', options: names.upper },
      properties: [
        { id: 'displayName', value: newNames.upper },
        { id: 'color', value: { fixedColor: 'blue', mode: 'fixed' } },
        { id: 'custom.lineWidth', value: 0 },
        { id: 'custom.fillBelowTo', value: newNames.lower },
        { id: 'custom.hideFrom', value: { legend: true } },
      ],
    },
    {
      matcher: { id: 'byName', options: names.actual },
      properties: [
        { id: 'displayName', value: newNames.actual },
        { id: 'color', value: { fixedColor: 'green', mode: 'fixed' } },
      ],
    },
  ];
};

/**
 * Check whether any series labels are duplicated across the input series.
 */
export function hasDuplicateSeriesLabels(series: DataFrame[]): boolean {
  return series
    .map((df) => {
      const labels = df.fields[1]?.labels;
      if (labels === undefined) {
        return '{}';
      }
      return JSON.stringify(labels, Object.keys(labels).sort());
    })
    .some((name, index, arr) => arr.indexOf(name) !== index);
}

/** Return an absolute URL for the 'view job' page of a job.
 *
 * This should work regardless of whether Grafana is running in a sub-path.
 *
 * `currentLocation` will default to `window.location.href` if left at its default
 * undefined value; this will generally be sufficient.
 */
export function absoluteUrlForJob(jobId: string, currentLocation?: string): string {
  const loc = currentLocation ?? window.location.href;
  const root = loc.substring(0, loc.indexOf(PLUGIN_ROOT));
  return `${root}${PLUGIN_ROOT}/metric-forecast/${jobId}`;
}

/**
 * Return a slugified version of a job name, suitable for a metric.
 */
export function slugifyName(name: string): string {
  return name
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLocaleLowerCase()
    .replace(/[^a-z0-9:_]+/g, '_');
}

/**
 * Calculate the minimum interval we can use for a given range,
 * without exceeding the maximum number of samples per series permitted
 * by the API (found in consts.ts).
 */
export function calculateMinInterval(
  range: number,
  rangeUnit: TimeUnit
): { intervalValue: number; intervalUnit: TimeUnit } {
  const options = [
    { value: 1, unit: TimeUnit.Minutes },
    { value: 5, unit: TimeUnit.Minutes },
    { value: 10, unit: TimeUnit.Minutes },
    { value: 15, unit: TimeUnit.Minutes },
    { value: 30, unit: TimeUnit.Minutes },
    { value: 1, unit: TimeUnit.Hours },
    { value: 2, unit: TimeUnit.Hours },
  ];
  const rangeInSeconds = timeUnitToSeconds(range, rangeUnit)!;
  let intervalValue = 1;
  let intervalUnit = TimeUnit.Minutes;
  for (const option of options) {
    const optionInSeconds = timeUnitToSeconds(option.value, option.unit)!;
    if (optionInSeconds <= rangeInSeconds && optionInSeconds * MAX_SAMPLES_PER_TRAINING >= rangeInSeconds) {
      intervalValue = option.value;
      intervalUnit = option.unit;
      break;
    }
  }

  return {
    intervalValue,
    intervalUnit,
  };
}
