import React, { ComponentType, PropsWithChildren, Suspense } from 'react';

import { LoadingPlaceholder, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

export function withSuspense<T>(Component: ComponentType<T>) {
  return function SuspendedComponent(props: PropsWithChildren<T>) {
    const styles = useStyles2(getStyles);

    return (
      <Suspense
        fallback={
          <div className={styles.loadingContainer}>
            <LoadingPlaceholder text="Loading" />
          </div>
        }
      >
        <Component {...props} />
      </Suspense>
    );
  };
}

const getStyles = () => {
  return {
    loadingContainer: css`
      width: 100%;
      text-align: center;
      margin-top: 20px;
    `,
  };
};
